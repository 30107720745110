@charset "utf-8";

.c-recruit-fixed {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 9999;
  transform: translateY(-50%);
  width: 200px;
  height: 200px;
  background-color: rgba($c--primary-light, .5);
  transition: .2s;
  &:hover {
    background-color: darken($c--primary-light, 6%);
    transition: .2s; }
  &[data-visivle="show"] {
    visibility: visible;
    opacity: 1; }
  &[data-visivle="hide"] {
    visibility: hidden;
    opacity: 0; }
  &::before {
    content: "";
    display: block;
    width: 180px;
    height: 180px;
    border: solid 1px $c--white;
    @include center(xy); }
  &__link {
    display: block;
    color: $c--primary;
    font-weight: bold;
    text-align: center;
    padding-top: 45px;
    position: relative;
    &::before {
      content: "";
      display: block;
      @include icon("_common/icn_recruit.png", 67px, 67px);
      @include center(x);
      top: 88px; } } }
