@charset "utf-8";

.p-thanks {
  width: 600px;
  margin: 40px auto 0;
  padding-bottom: 80px;
  @include max-screen($breackpoint_sp) {
    width: 100%;
    max-width: 600px;
    margin: 20px auto 0;
    padding: 0 10px; }
  &__head {
    color: $c--white;
    font-size: 15px;
    padding: 12px 16px;
    background-color: $c--black; }
  &__desc {
    margin-top: 16px;
    font-size: 14px;
    line-height: 2; }
  &__link {
    font-size: 14px;
    text-align: center;
    margin-top: 32px;
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none; } } } }
