@charset "utf-8";

html {
	font-size: 62.5%; }

body,
input,
button,
textarea,
select {
	color: $c--txt;
	font-size: 1.6rem;
	font-family:  Lato, "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	line-height: 1.5; }
