.c-footer {
  color: $c--white;
  background-color: #a7a3a1;
  margin-top: 100px;
  padding: 30px 0;
  @include max-screen($breackpoint_sp) {
    text-align: center;
    margin-top: 60px;
    padding-bottom: 120px; } // スクロールアイコン分、下に伸ばす
  &-upper {
    font-size: 14px;
    font-weight: bold; }
  &-main {
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    @include max-screen($breackpoint_sp) {
      font-size: 13px;
      font-weight: normal;
      margin-top: 16px; }
    &__lists {
      display: flex;
      @include max-screen($breackpoint_sp) {
        justify-content: center;
        flex-wrap: wrap;
        margin-top: -12px; } }
    &__item {
      margin-left: 12px;
      padding-left: 12px;
      border-left: solid 1px $c--white;
      @include max-screen($breackpoint_sp) {
        margin-top: 12px; }
      a {
        color: $c--white;
        &:hover {
          text-decoration: underline; } }
      &:first-of-type {
        margin-left: 0;
        padding-left: 0;
        border-left: none; } } }
  &-foot {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    margin-top: 24px;
    @include max-screen($breackpoint_sp) {
      justify-content: center;
      flex-direction: column; }
    &__copyright {
      @include max-screen($breackpoint_sp) {
        margin-top: 40px; } } } }
