@charset "utf-8";

.c-scroll-btn {
  position: fixed;
  left: 50%;
  bottom: 30px;
  z-index: 9990;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border-radius: 4px;
  transition: .2s;
  &[data-visivle="show"] {
    visibility: visible;
    opacity: 1; }
  &[data-visivle="hide"] {
    visibility: hidden;
    opacity: 0; }
  &[data-direction="top"] {
    transform: translateX(-50%) rotate(180deg); }
  a {
    display: block;
    width: 60px;
    height: 60px;
    transition: .2s;
    &:hover {
      opacity: .5;
      transition: .2s; } } }
