@charset "utf-8";

.p-section-contact {
  &__form {
    font-size: 12px;
    padding-left: 40px;
    @include max-screen($breackpoint_sp) {
      padding-left: 0; } }
  &__item {
    display: flex;
    margin-top: 3px;
    @include max-screen($breackpoint_sp) {
      flex-direction: column;
      margin-top: 15px; }
    &:first-of-type {
      margin-top: 0; } }
  &__head {
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;
    width: 88px;
    padding-top: 14px;
    @include max-screen($breackpoint_sp) {
      justify-content: flex-start;
      padding-top: 0; } }
  &__desc {
    margin-left: 46px;
    width: 100%;
    label.error {
      display: block;
      color: #e63838;
      margin: 4px 0 16px; }
    @include max-screen($breackpoint_sp) {
      margin-top: 3px;
      margin-left: 0;
      font-size: 13px; } }
  &__btn {
    transform: translateX(-20px);
    @include max-screen($breackpoint_sp) {
      transform: translateX(0); } } }
