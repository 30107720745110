@charset "utf-8";

.p-section-project {
  @include max-screen($breackpoint_sp) {
    margin-top: 0; }
  &__content {
    display: flex;
    flex-wrap: wrap;
    @include max-screen($breackpoint_sp) {
      display: block; }
    &[data-column="1"] {
      display: block; }
    &[data-column="2"] > * {
      width: 48%;
      margin-left: 4%;
      &:nth-of-type(2n+1) {
        margin-left: 0; } }
    &[data-column="3"] > * {
      width: 31%;
      margin-left: 3.5%;
      &:nth-of-type(3n+1) {
        margin-left: 0; } }
    &[data-column="4"] > * {
      width: 23.5%;
      margin-left: 2%;
      &:nth-of-type(4n+1) {
        margin-left: 0; } } }
  &__item {
    margin-bottom: 64px;
    @include max-screen($breackpoint_sp) {
      width: 100% !important;
      margin-left: 0 !important; } }
  &__thumb {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: $c--primary;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      @include max-screen($breackpoint_sp) {
        display: none; } }
    a {
      display: block;
      background-color: #dbdbdc;
      position: relative;
      z-index: 20;
      transition: .2s;
      @include max-screen($breackpoint_sp) {
        background-color: transparent;
        max-width: 500px;
        margin: auto; }
      &:hover {
        transform: translate(-5px, -5px);
        transition: .2s; } } }

  &__head {
    margin-top: 20px;
    font-size: 15px;
    font-weight: bold; }
  &__desc {
    margin-top: 12px;
    color: $c--gray-2;
    font-size: 14px;
    @include max-screen($breackpoint_sp) {
      font-size: 13px; } }
  &__link {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    a {
      display: inline-block;
      color: $c--white;
      font-size: 13px;
      background-color: #a7a3a1;
      padding: 2px 32px;
      padding-right: 8px;
      width: auto;
      position: relative;
      &::before,
      &::after {
        content: "";
        display: block;
        @include center(y); }
      &::before {
        @include triangle(right, 6px, 8px, $c--white);
        left: 22px; }
      &::after {
        @include triangle(right, 6px, 8px, #a7a3a1);
        left: 20px;
        transition: .2s; }
      &:hover {
        background-color: $c--primary;
        transition: .2s;
        &::after {
          @include triangle(right, 6px, 8px, $c--primary);
          transition: .2s; } } } } }
