@charset "utf-8";

.p-section-service {
  &__lists {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include max-screen($breackpoint_sp) {
      flex-direction: column;
      align-items: center; } }
  &__item {
    $parent: &;
    width: 308px;
    margin-top: 30px;
    padding: 32px 0 60px;
    position: relative;
    @include max-screen($breackpoint_sp) {
      margin-top: 10px;
      padding: 16px 16px;
      width: 100%;
      max-width: 330px; }
    &:nth-of-type(-n+3) {
      margin-top: 0;
      @include max-screen($breackpoint_sp) {
        margin-top: 10px; } }
    &:first-of-type {
      @include max-screen($breackpoint_sp) {
        margin-top: 0; } }
    &::before {
      content: "";
      display: block;
      @include center(x);
      top: 64px;
      @include max-screen($breackpoint_sp) {
        @include center(y);
        left: 16px;
        transform: translateY(-50%) scale(.7); } }
    &--scheme {
      @extend #{$parent};
      &::before {
        @include icon("service/icn_scheme.png", 34px, 28px); } }
    &--produce {
      @extend #{$parent};
      &::before {
        @include icon("service/icn_produce.png", 27px, 28px); } }
    &--maked {
      @extend #{$parent};
      &::before {
        @include icon("service/icn_maked.png", 33px, 33px); } }
    &--facilities {
      @extend #{$parent};
      &::before {
        @include icon("service/icn_facilities.png", 34px, 35px); } }
    &--event {
      @extend #{$parent};
      &::before {
        @include icon("service/icn_event.png", 29px, 29px); } }
    &--books {
      @extend #{$parent};
      &::before {
        @include icon("service/icn_books.png", 34px, 28px); } } }
  &__head {
    text-align: center;
    @include max-screen($breackpoint_sp) {
      text-align: left;
      font-weight: bold;
      padding-left: 40px; }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 98%;
      height: 1px;
      background-color: $c--primary-light;
      @include center(x); }
    &::before {
      top: 0; }
    &::after {
      bottom: 0; }
    b {
      &::before,
      &::after {
        content: "";
        display: block;
        width: 1px;
        height: 92%;
        background-color: $c--primary-light;
        @include center(y); }
      &::before {
        left: 0; }
      &::after {
        right: 0; } } } }
