@charset "utf-8";

.c-form {

  input,
  button,
  textarea,
  select {
    color: $c--gray-2;
    font-size: 12px;
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

  textarea {
    min-height: 140px;
    resize: vertical; }

  button {
    color: $c--primary;
    font-size: 12px;
    font-weight: bold;
    max-width: 210px;
    margin: 30px auto 0;
    padding: 18px 20px;
    border: solid 2px #d8e2e2;
    cursor: pointer;
    transition: .2s;
    &:hover {
      color: $c--white;
      background-color: $c--primary;
      transition: .2s; } }

  ::placeholder {
    color: $c--gray-1; }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  input[type="date"],
  input[type="password"],
  textarea,
  select {
    display: block;
    background-color: $c--white;
    border: solid 1px $c--gray-0;
    padding: 14px 16px;
    &:-webkit-autofill {
      // オートコンプリート機能で入力したら背景色が薄い黄色になるのを任意の色に変える
      -webkit-box-shadow: 0 0 0px 1000px $c--white inset; } } }
