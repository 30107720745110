@charset "utf-8";

.l-inner {
  width: $breackpoint_pc;
  margin: auto;
  @include max-screen($breackpoint_sp) {
    width: 100%;
    padding: 0 10px; }
  &--small {
    width: 820px;
    margin: auto;
    @include max-screen($breackpoint_sp) {
      width: 100%;
      padding: 0 10px; } } }

.l-main {
  margin-top: 100px; // header固定分
  @include max-screen($breackpoint_sp) {
    margin-top: 66px; } } // header固定分
