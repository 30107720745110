@charset "utf-8";

.p-section-msg {
  $parent: &;
  overflow: hidden;
  padding-bottom: 164px;
  @include max-screen($breackpoint_sp) {
    padding-bottom: 200px; }
  &__section {
    padding-top: 200px;
    padding-right: 50px;
    padding-bottom: 2px;
    position: relative;
    @include max-screen($breackpoint_sp) {
      margin-top: 150px;
      padding: 0; }
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute; }
    &::before {
      top: -2px; }
    &::after {
      bottom: -162px; }
    &:first-of-type {
      padding-top: 100px;
      @include max-screen($breackpoint_sp) {
        margin-top: 0;
        padding-top: 40px; }
      #{$parent}__inner {
        @include max-screen($breackpoint_sp) {
          transform: translateY(0); } } }
    &:nth-of-type(2n) {
      padding-left: 50px;
      padding-right: 0;
      background-color: $c--gray-0;
      @include max-screen($breackpoint_sp) {
        padding-left: 0;
        background-color: transparent; }
      &::before {
        @include triangle(bottom, 118vw, 164px, $c--white);
        left: -16vw;
        @include max-screen($breackpoint_sp) {
          border-width: 0 0 200px 102vw;
          border-color: transparent transparent $c--gray-0 transparent;
          top: -198px;
          left: -1vw;
          right: auto; }
        @include max-screen(500px) {
          border-width: 0 0 100px 102vw;
          top: -98px; } }
      &::after {
        @include triangle(bottom, 118vw, 164px, $c--gray-0);
        right: -16vw;
        @include max-screen($breackpoint_sp) {
          border-width: 200px 102vw 0 0;
          border-color: $c--gray-0 transparent transparent transparent;
          bottom: -198px;
          left: -1vw;
          right: auto; }
        @include max-screen(500px) {
          border-width: 100px 102vw 0 0;
          bottom: -98px; } }
      #{$parent}__content {
        @include max-screen($breackpoint_sp) {
          background-color: $c--gray-0; } }
      #{$parent}__img {
        order: 2;
        @include max-screen($breackpoint_sp) {
          order: 1; } }
      #{$parent}__meta {
        order: 1;
        @include max-screen($breackpoint_sp) {
          prder: 2; } }
      #{$parent}__head {
        text-align: left;
        @include max-screen($breackpoint_sp) {
          text-align: center; } }
      #{$parent}__tag {
        justify-content: flex-start;
        @include max-screen($breackpoint_sp) {
          justify-content: center; } } } }
  &__inner {
    display: flex;
    justify-content: space-between;
    @include max-screen($breackpoint_sp) {
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 1;
 } }      // transform: translateY(-100px)
  &__img {
    width: 600px;
    &[data-aos] {
      transform: translateY(10px);
      transition: .6s ease-in-out !important; }
    @include max-screen($breackpoint_sp) {
      width: 100%;
      max-width: 600px; } }
  &__meta {
    width: 300px;
    &[data-aos] {
      transform: translateY(10px);
      transition: .6s ease-in-out !important;
      transition-delay: .3s !important; }
    @include max-screen($breackpoint_sp) {
      width: 100%;
      max-width: 300px; } }
  &__head {
    font-size: 30px;
    text-align: right;
    @include mincho;
    @include max-screen($breackpoint_sp) {
      margin-top: 20px;
      text-align: center; } }
  &__tag {
    display: flex;
    justify-content: flex-end;
    @include max-screen($breackpoint_sp) {
      justify-content: center; }
    span {
      color: $c--white;
      font-size: 15px;
      margin-top: 20px;
      padding: 8px 30px;
      display: inline-block;
      background-color: $c--primary;
      border: solid 1px $c--primary-light;
      @include max-screen($breackpoint_sp) {
        min-width: 180px;
        text-align: center;
        margin-top: 10px; } } }
  &__desc {
    color: $c--gray-2;
    font-size: 14px;
    margin-top: 20px;
    span {
      font-weight: bold; }
    br {
      @include max-screen($breackpoint_sp) {
        display: none; } } } }
