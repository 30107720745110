@charset "utf-8";

.c-header {
  padding: 33px 0 28px;
  background-color: $c--white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  @include max-screen($breackpoint_sp) {
    padding: 0 0 0; }
  &__inner {
    display: flex;
    justify-content: space-between; }
  &__logo {
    width: 88px;
    @include max-screen($breackpoint_sp) {
      width: 58px;
      margin-top: 20px; } }
  &-nav {
    @include max-screen($breackpoint_sp) {
      position: fixed;
      left: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      background-color: $c--white;
      transition: .45s cubic-bezier(.35, .01, .3, 1);
      opacity: 0;
      visibility: hidden;
      &[data-visivle="show"] {
        opacity: 1;
        visibility: visible;
        top: 60px; }
      &[data-visivle="hide"] {
        opacity: 0;
        visibility: hidden;
        top: 120px; } }
    &__lists {
      display: flex;
      align-items: center;
      @include max-screen($breackpoint_sp) {
        flex-direction: column;
        padding-top: 40px; } }
    &__item {
      margin-left: 16px;
      @include max-screen($breackpoint_sp) {
        margin-left: 0;
        width: 100%; }
      &:first-of-type {
        margin-left: 0; }
      a {
        color: $c--gray-1;
        font-size: 14px;
        position: relative;
        @include max-screen($breackpoint_sp) {
          display: block;
          font-size: 16px;
          text-align: center;
          padding: 16px 8px; }
        &::before {
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background-color: $c--primary;
          position: absolute;
          bottom: -10px;
          left: 50%;
          transition: .45s cubic-bezier(.35, .01, .3, 1);
          @include max-screen($breackpoint_sp) {
            display: none; } }
        &:hover {
          &::before {
            width: 100%;
            left: 0;
            transition: .45s cubic-bezier(.35, .01, .3, 1); } } } } }
  &-sp {
    &__content {
      display: flex; }
    &__recruit {
      @extend %__sp-common;
      a {
        position: relative;
        &::before {
          content: "";
          display: block;
          @include icon("_common/icn_recruit_white.png", 25px, 25px);
          @include center(x);
          top: 12px; } } }
    &__humbager {
      @extend %__sp-common;
      margin-left: 1px;
      &[data-status="close"] {
        ul {
          li {
            &:nth-of-type(1) {
              transform: translate(-50%, -50%) rotate(-45deg);
              margin: 0; }
            &:nth-of-type(2) {
              opacity: 0;
              left: 100%; }
            &:nth-of-type(3) {
              transform: translate(-50%, -50%) rotate(45deg);
              margin: 0; } } } }
      ul {
        li {
          width: 28px;
          height: 2px;
          background-color: $c--white;
          @include center(xy);
          top: 42%;
          transition: .2s;
          &:nth-of-type(1) {
            margin-top: -8px; }
          &:nth-of-type(2) {
            margin-top: 0; }
          &:nth-of-type(3) {
            margin-top: 8px; } } } } } }

%__sp-common {
  width: 60px;
  height: 60px;
  background-color: $c--primary;
  position: relative;
  a {
    display: block; }
  span {
    display: block;
    color: $c--white;
    font-size: 10px;
    text-align: center;
    @include center(x);
    bottom: 4px; } }
