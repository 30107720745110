@charset "utf-8";

// ==============================================================
//  background-image
// ==============================================================
@mixin background-image($fileName, $repeat: no-repeat) {
  background-image: url($path_img + $fileName);
  background-repeat: $repeat;
  background-position: 50% 50%; }

// ==============================================================
//  box-shadow
// ==============================================================
@mixin box-shadow {
  box-shadow: 1px 2px 13px 0px rgba(0, 0, 0, .2); }

// ==============================================================
//  clear-fix
// ==============================================================
@mixin cf {
  zoom: 1;
  &:after {
    content: "";
    display: block;
    clear: both; } }

// ==============================================================
//  counter-increment
// ==============================================================
@mixin counter($numName, $beforeText, $afterText) {
  content: "#{$beforeText}" counter(num_ + $numName) "#{$afterText}";
  counter-increment: num_ + $numName; }

// ==============================================================
//  gradient
// ==============================================================
@mixin gradient($startColor, $endColor, $orientation) {
  background: $startColor;
  @if $orientation == y {
    background: linear-gradient(to bottom, $startColor 0%, $endColor 100%); }
  @else if $orientation == x {
    background: linear-gradient(to right, $startColor 0%, $endColor 100%); }
  @else {
    background: linear-gradient($orientation, $startColor 0%, $endColor 100%); } }

// ==============================================================
//  icon
// ==============================================================
@mixin icon($fileName, $width, $height) {
  background: url($path_img + $fileName) no-repeat 50% 50%;
  background-size: $width $height;
  width: $width;
  height: $height; }

// ==============================================================
//  media screen
// ==============================================================
@mixin max-screen($breakPoint) {
  @media screen and (max-width: $breakPoint) {
    @content; } }

@mixin min-screen($breakPoint) {
  @media screen and (min-width: $breakPoint) {
    @content; } }

@mixin screen($breakPoint_min, $breakPoint_max) {
  @media screen and (min-width: $breakPoint_min) and (max-width: $breakPoint_max) {
    @content; } }

// ==============================================================
//  hover
// ==============================================================
@mixin hover {
  @include min-screen($breackpoint_sp) {
    transition: .2s;
    &:hover {
      @include min-screen($breackpoint_sp) {
        transition: .3s;
        opacity: .5;
        @content; } } } }

// ==============================================================
//  position absolute center
// ==============================================================
@mixin center($direction) {
  position: absolute;
  @if $direction==xy {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @else if $direction==x {
    left: 50%;
    transform: translateX(-50%); }
  @else if $direction==y {
    top: 50%;
    transform: translateY(-50%); } }

// ==============================================================
//  triangle
// ==============================================================
@mixin triangle($direction, $width, $height, $color) {
  width: 0;
  height: 0;
  border-style: solid;
  $valWidth: $width / 2;
  $valHeight: $height / 2;
  @if ($direction == top) {
    border-width: 0 $valWidth $height $valWidth;
    border-color: transparent transparent $color transparent; }
  @else if ($direction == left) {
    border-width: $valHeight $width $valHeight 0;
    border-color: transparent $color transparent transparent; }
  @else if ($direction == right) {
    border-width: $valHeight 0 $valHeight $width;
    border-color: transparent transparent transparent $color; }
  @else if ($direction == bottom) {
    border-width: $height $valWidth 0 $valWidth;
    border-color: $color transparent transparent transparent; } }

// ==============================================================
//  txt-under-line
// ==============================================================
@mixin underline-txt($color: $YELLOW, $height: 65%) {
  background: linear-gradient(transparent $height, $color 0%);
  display: inline; }

// ==============================================================
//  ハック
// ==============================================================
// ie9, 10, 11
@mixin ie9 {
  @media screen and (min-width:0\0) {
    @content; } }

// pc safari, sp safari, sp googleChrome
@mixin safari($parent) {
  @at-root _::-webkit-full-page-media, _:future, :root #{$parent} {
    @content; } }
