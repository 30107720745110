@charset "utf-8";

.c-heading {
  text-align: center;
  + * {
    margin-top: 30px; }
  &__en {
    font-size: 19px;
    letter-spacing: 1em;
    @include max-screen($breackpoint_sp) {
      font-weight: bold; } }
  &__ja {
    font-size: 12px;
    letter-spacing: 1em;
    margin-top: 4px; } }
