@charset "utf-8";

.p-recruit {
  &__section {
    margin-top: 60px;
    padding-top: 60px;
    border-top: dashed 1px #eee;
    &:first-of-type {
      margin-top: 0;
      padding-top: 0;
      border-top: none; } }
  &__head {
    font-size: 30px;
    @include mincho;
    margin-top: 30px;
    @include max-screen($breackpoint_sp) {
      font-size: 24px; } }
  &__head-sub {
    color: $c--primary;
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px; }
  &__desc {
    margin-top: 16px;
    color: $c--gray-2;
    p + p {
      margin-top: 16px; } }
  &__fs {
    &-lg {
      font-size: 18px; }
    &-md {
      font-size: 16px; }
    &-sm {
      font-size: 14px; } }
  &__mt {
    &-lg {
      margin-top: 30px; }
    &-md {
      margin-top: 16px; }
    &-sm {
      margin-top: 8px; } }
  &__tag {
    color: $c--gray-2;
    background-color: #d8e2e2;
    display: inline-block;
    padding: 12px 30px;
    position: relative;
    &::before,
    &::after,
    span::before,
    span::after, {
      content: "";
      display: block;
      background-color: #aec3c3;
      position: absolute; }
    &::before,
    span::before, {
      width: 20px;
      height: 1px; }
    &::after,
    span::after, {
      width: 1px;
      height: 20px; }
    &::before,
    &::after {
      top: 2px;
      left: 2px; }
    span::before,
    span::after {
      bottom: 2px;
      right: 2px; } }
  &-column {
    $parent: &;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    &--2 {
      @extend #{$parent};
      &__item {
        width: 48%; } }
    &--4 {
      @extend #{$parent};
      &__item {
        width: 24%; } }
    @include max-screen($breackpoint_sp) {
      flex-direction: column;
      > * {
        margin-top: 48px;
        width: 100%;
        &:first-of-type {
          margin-top: 0; }
        img {
          display: block;
          margin: auto; } } } }
  &-table {
    margin-top: 30px;
    &__item {
      margin-top: 10px;
      background-color: $c--gray-0;
      display: flex;
      align-items: center;
      @include max-screen($breackpoint_sp) {
        flex-direction: column;
        align-items: flex-start;
        padding: 12px 16px; } }
    &__head {
      color: $c--primary;
      font-weight: bold;
      width: 200px;
      padding: 20px 40px;
      padding-right: 20px;
      @include max-screen($breackpoint_sp) {
        width: 100%;
        padding: 0; } }
    &__desc {
      color: $c--gray-2;
      padding: 20px 0;
      padding-right: 20px;
      @include max-screen($breackpoint_sp) {
        padding: 0;
        margin-top: 2px;
        font-size: 15px; } } }
  &__first-view-img {
    padding-top: 30px; } }
