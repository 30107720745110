@charset "utf-8";

.c-recruit-foot {
  $parent: &;
  width: 600px;
  margin: 100px auto 0;
  text-align: center;
  position: relative;
  @include max-screen($breackpoint_sp) {
    width: 100%;
    max-width: 300px;
    margin: 60px auto 0; }
  a {
    display: block;
    padding: 13px 0 12px;
    background-color: #d8e2e2;
    border: solid 2px $c--primary-light;
    position: relative;
    transition: .2s;
    &:hover {
      background-color: darken($c--primary-light, 20%);
      transition: .2s;
      #{$parent}__head-en,
      #{$parent}__head-ja {
        color: $c--white; }
      &::before {
        opacity: 0;
        transition: .2s; }
      &::after {
        opacity: 1;
        transition: .2s; } }
    &::before,
    &::after {
      content: "";
      display: block;
      @include center(y);
      right: 120px;
      transition: .2s;
      @include max-screen($breackpoint_sp) {
        right: 16px;
        top: 40%; } }
    &::before {
      @include icon("_common/icn_recruit.png", 30px, 30px); }
    &::after {
      @include icon("_common/icn_recruit_white.png", 30px, 30px);
      opacity: 0; } }
  &__head-en {
    display: block;
    color: $c--primary;
    font-size: 19px;
    font-weight: bold;
    letter-spacing: 1em;
    @include max-screen($breackpoint_sp) {
      font-size: 18px; } }
  &__head-ja {
    display: block;
    font-size: 10px;
    font-weight: bold;
    margin-top: 2px;
    letter-spacing: 1em; } }
