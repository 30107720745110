@charset "utf-8";

// ==============================================================
//  breack points
// ==============================================================
$breackpoint_pc: 980px;
// $breackpoint_tb: 960px
$breackpoint_sp: 980px;
// $breackpoint_sp_small: 480px

// ==============================================================
//  path
// ==============================================================
$path_img: "../img/";

// ==============================================================
//  color
// ==============================================================
$c--primary: #326c6c;
$c--primary-light: #aec3c3;
// $c--primary-dark: darken($c--primary, 15%)

// $c--accent: #dea116

$c--black: #333;
$c--white: #fff;

$c--gray-0: #eee;
$c--gray-1: #989898;
$c--gray-2: #666;

$c--warning: #da9e9e;

$c--txt: $c--black;
