@charset "utf-8";

.p-section-company {
  &__table {
    font-size: 12px;
    border-bottom: solid 1px $c--gray-0; }
  &__item {
    display: flex;
    padding: 20px 40px;
    border-top: solid 1px $c--gray-0;
    @include max-screen($breackpoint_sp) {
      flex-direction: column;
      padding: 15px 10px; } }
  &__head {
    width: 88px;
    display: flex;
    justify-content: space-between;
    @include max-screen($breackpoint_sp) {
      width: 100%;
      justify-content: flex-start; } }
  &__desc {
    margin-left: 60px;
    @include max-screen($breackpoint_sp) {
      margin-left: 0;
      font-size: 13px; } } }
